import { StepSyncProgress, type IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface ISyncProgressJSON {
    resourcesProgress: Array<IStepSyncProgressJSON>;
    markedAsCanceled: boolean;
    currentStepIndex: number;
    dateTimeStart: string;
    dateTimeEnd: string;
    requirePageReload: boolean;
}

export interface ISyncProgressOptions {
    markedAsCanceled?: boolean;
    currentStepIndex?: number;
    dateTimeStart?: Date;
    dateTimeEnd?: Date;
    requirePageReload?: boolean;
}

export class SyncProgress {
    public resourcesProgress: Array<StepSyncProgress> = new Array<StepSyncProgress>();
    public markedAsCanceled: boolean = false;
    public currentStepIndex: number = 0;
    public dateTimeStart: Date = new Date();
    public dateTimeEnd: Date | undefined;
    public requirePageReload: boolean = false;
    
    public get hasError(): boolean {
        return this.resourcesProgress.some((resourceProgress, index, array) => {
            return this._hasError(resourceProgress);
        });
    }

    public get complete(): boolean {
        return this.dateTimeEnd instanceof Date;
    }

    public get currentStep(): number {
        return this.currentStepIndex + 1;
    }

    public get currentSyncStepProgress(): StepSyncProgress {
        return this.resourcesProgress[this.currentStepIndex];
    }

    public get currentSyncProgressPending(): number {
        let countedSteps = 0;
        
        const pendingTotal = this.resourcesProgress.reduce((n, { progressStatusPending }) => {
            if (!isNaN(progressStatusPending)) {
                countedSteps++;
                return n + (progressStatusPending > 100 ? 100 : progressStatusPending);
            }

            return n;
        }, 0);
        
        return pendingTotal / countedSteps;
    }

    public get currentSyncProgressSuccess(): number {
        let countedSteps = 0;

        const successTotal = this.resourcesProgress.reduce((n, { progressStatusSuccess }) => {
            if (!isNaN(progressStatusSuccess)) {
                countedSteps++;
                return n + (progressStatusSuccess > 100 ? 100 : progressStatusSuccess);
            }

            return n;
        }, 0);
        
        return successTotal / countedSteps;
    }

    public get currentSyncProgressError(): number {
        let countedSteps = 0;

        const errorTotal = this.resourcesProgress.reduce((n, { progressStatusError }) => {
            if (!isNaN(progressStatusError)) {
                countedSteps++;
                return n + progressStatusError;
            }

            return n;
        }, 0);
        
        return errorTotal / countedSteps;
    }

    constructor(options: ISyncProgressOptions | ISyncProgressJSON = null) {
        options?.markedAsCanceled && (this.markedAsCanceled = options.markedAsCanceled);
        options?.currentStepIndex && (this.currentStepIndex = options.currentStepIndex);
        options?.dateTimeStart && (typeof options.dateTimeStart === 'string') && (this.dateTimeStart = new Date(options.dateTimeStart));
        options?.dateTimeStart && (typeof options.dateTimeStart !== 'string') && (this.dateTimeStart = options.dateTimeStart);
        options?.dateTimeEnd && (typeof options.dateTimeEnd === 'string') && (this.dateTimeEnd = new Date(options.dateTimeEnd));
        options?.dateTimeEnd && (typeof options.dateTimeEnd !== 'string') && (this.dateTimeEnd = options.dateTimeEnd);
        options?.requirePageReload && (this.requirePageReload = options.requirePageReload);
    }

    private _hasError(resourceSyncProgress: StepSyncProgress): boolean {
        return resourceSyncProgress.hasError;
    }

    public toJSON(): ISyncProgressJSON {
        return Object.assign({}, this, {
            resourcesProgress: this.resourcesProgress.map((resourceProgress) => { return resourceProgress.toJSON() }),
            dateTimeStart: this.dateTimeStart.toISOString(),
            dateTimeEnd: this.dateTimeEnd?.toISOString(),
        });
    }

    public markSyncAsCanceled(): void {
        this.markedAsCanceled = true;
    }
}
